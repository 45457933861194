import Vue from 'vue'
import {
    Button, Cell, Field, CellGroup,
    Notify, Form, Radio, RadioGroup,
    checkbox ,Toast 
} from 'vant'

Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Button)
Vue.use(Cell)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Form);
Vue.use(checkbox);
Vue.use(Toast);


Vue.prototype.$Notify = Notify;

Vue.prototype.showNotify = function (type, message) {
    this.$Notify({ type, message })
}


//弹窗
Vue.prototype.confirm = function (val) {
    this.$dialog.confirm({
        title: '提示',
        message: val,
        showCancelButton: false,
    }).then(() => {

    }).catch(() => {

    });
}