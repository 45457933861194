import Vue from 'vue'
import Enter from './App.vue'
import router from './router'
import App from './core.js';
let Base64 = require('js-base64').Base64;
Vue.prototype.$Base64 = Base64;
window.debug = process.env.NODE_ENV=="development";
window.is_debug = window.debug;


let app = new App;



// 一些公共的方法`


//滚动插件
import VueScroller from './components/scroller/index.js';
Vue.use(VueScroller);

Vue.config.productionTip = false

import './plugin/vant'

new Vue({
  router,
 
  render: h => h(Enter)
}).$mount('#app')
